import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import './nav.css'
import LogoMaroon from '../images/williams-wine.png'
import LogoWhite from '../images/williams-white.png'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileMenu: false
    }
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
}

toggleMobileMenu () {
  this.setState( prevState => ({
    mobileMenu: !prevState.mobileMenu
  }));
}


  render() {
    let {siteTitle, textColorClass, logoVersion} = this.props;
    return (
      <header>
      <div className="logo-li"><Link to="/"><img className="nav-logo" src={logoVersion} /></Link></div>
        <div className="menu-btn" id="menu-btn" onClick={this.toggleMobileMenu}>
            <div></div>
            <span></span>
            <span></span>
            <span></span>
         </div>
    
       
         <div className="responsive-menu">
            <ul className={'product-nav ' + textColorClass}>
             <li><Link activeClassName="active-nav" className="nav-item" to="/Products">Products</Link></li>
            <li><Link activeClassName="active-nav"  className="nav-item" to="/Team">Team</Link></li>
            <li><Link activeClassName="active-nav"  className="nav-item" to="/Contact">Contact</Link></li>
            </ul>
         </div>
         {this.state.mobileMenu ? 
         <div className="mobile-menu-wrapper">
         <Link to="/"><img className="mobile-logo" src={LogoWhite} /></Link>
         <div className="mobile-close" onClick={this.toggleMobileMenu}>x</div>
            <ul className={'mobile-nav-ul '}>
             <li><Link className="nav-item-mobile" to="/Products">Products</Link></li>
            <li><Link className="nav-item-mobile" to="/Team">Team</Link></li>
            <li><Link className="nav-item-mobile" to="/Contact">Contact</Link></li>
            </ul>
         </div> : null}
    
      </header>
    )
  }
}
// const Header = ({ siteTitle, textColorClass, logoVersion}) => (
//   <header>
//   <div className="logo-li"><Link to="/"><img className="nav-logo" src={logoVersion} /></Link></div>
// 		<div className="menu-btn" id="menu-btn">
// 				<div></div>
// 				<span></span>
// 				<span></span>
// 				<span></span>
// 		 </div>

//      <div className="responsive-menu">
//         <ul className={'product-nav ' + textColorClass}>
//        	<li><Link className="nav-item" to="/Products">Products</Link></li>
// 				<li><Link className="nav-item active" to="/Team">Team</Link></li>
// 				<li><Link className="nav-item" to="/Contact">Contact</Link></li>
//         </ul>
//      </div>

//   </header>
// )

Header.propTypes = {
  siteTitle: PropTypes.string,
  textColorClass: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  textColorClass: 'maroon-text',
  logoVersion: LogoMaroon
}

export default Header
