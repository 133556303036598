import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import './nav.css'

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: ""
        }
      }
    
    


    componentDidMount() {
        this.setState({
            year: new Date().getFullYear()
        })
    }

    render() {
        return (
            <div className="footer-wrapper">
            <footer>
                <div className="connect-box">
                    <h3>Connect</h3>
                    <ul className="footer-connects">
                        <li><a className="icon" href="mailto:jwilliams@williamsrobotics.com?subject=web response">b</a></li>
                        <li><a class="icon" href="tel:919-724-2869">c</a></li>	
                    </ul>
                </div>	
    
                <div className="copywrite"> {this.state.year} Williams Robotics, INC Burlington, NC</div>
    
                <img className="section-logo" src="https://s3-us-west-2.amazonaws.com/williams-robotics/williams-wine.png"/>
            </footer>
        </div>
        )
    }

}
export default Footer

